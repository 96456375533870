import React from 'react';
import { useTranslation } from 'react-i18next';

import accentureLogo from '../../assets/svg/clients-logo/accenture-logo.svg';
import bankOfAmericaLogo from '../../assets/svg/clients-logo/bank-of-america-logo.svg';
import bordGaisEnergyLogo from '../../assets/svg/clients-logo/bord-gais-energy-logo.svg';
import britishTelecomLogo from '../../assets/svg/clients-logo/british-telecom-logo.svg';
import googleLogo from '../../assets/svg/clients-logo/google-logo.svg';
import grouponLogo from '../../assets/svg/clients-logo/groupon-logo.svg';
import nttLogo from '../../assets/svg/clients-logo/ntt-logo.svg';
import openjawLogo from '../../assets/svg/clients-logo/openjaw-logo.svg';
import roomexLogo from '../../assets/svg/clients-logo/roomex-logo.svg';

import './SocialProof.scss';

const SocialProof = ({ showAll }) => {
  const { t } = useTranslation();

  return (
    <>
      {!!showAll && (
        <div className="row justify-content-center">
          <h2 className="col-lg-9 text-center text-pre-line">{t('homePage.socialProof.title')}</h2>
        </div>
      )}

      <div className="row align-items-center justify-content-between text-center pt-3">
        <figure className="col-4 col-lg-2">
          <img
            className="max-w-11rem max-w-md-13rem mx-auto filter-grey"
            src={bankOfAmericaLogo}
            alt="Bank of America"
          />
        </figure>
        <figure className="col-4 col-lg-2">
          <img
            className="max-w-11rem max-w-md-13rem mx-auto filter-grey"
            src={googleLogo}
            alt="Google"
          />
        </figure>
        <figure className="col-4 col-lg-2">
          <img
            className="max-w-11rem max-w-md-13rem mx-auto filter-grey"
            src={bordGaisEnergyLogo}
            alt="Bord Gais Energy"
          />
        </figure>
        <figure className="col-4 col-lg-2">
          <img
            className="max-w-11rem max-w-md-15rem mx-auto filter-grey"
            src={grouponLogo}
            alt="Groupon"
          />
        </figure>
        <figure className="col-4 col-lg-2">
          <img
            className="max-w-11rem max-w-md-15rem mx-auto filter-grey"
            src={roomexLogo}
            alt="Roomex"
          />
        </figure>
        <figure className="col-4 col-lg-2">
          <img
            className="max-w-13rem max-w-md-17rem mx-auto filter-grey"
            src={accentureLogo}
            alt="Accenture"
          />
        </figure>
      </div>

      {!!showAll && (
        <div
          className="row align-items-center justify-content-between text-center pt-4"
          data-aos="fade-up"
        >
          <figure className="col-4 col-lg-4 text-lg-right">
            <img
              className="max-w-11rem max-w-md-13rem mx-auto filter-grey"
              src={britishTelecomLogo}
              alt="British Telecom"
            />
          </figure>
          <figure className="col-4 col-lg-4">
            <img
              className="max-w-11rem max-w-md-17rem mx-auto filter-grey"
              src={openjawLogo}
              alt="OpenJaw"
            />
          </figure>
          <figure className="col-4 col-lg-4 text-lg-left">
            <img
              className="max-w-11rem max-w-md-13rem mx-auto filter-grey"
              src={nttLogo}
              alt="NTT"
            />
          </figure>
        </div>
      )}
    </>
  );
};

export default SocialProof;
