import React from 'react';

import abstractShapesBottom from '../assets/svg/components/abstract-shapes-bottom.svg';
import abstractShapesTop from '../assets/svg/components/abstract-shapes-top.svg';

const SVG = ({type}) => {
    switch(type) {
        case 'ShapeBottom':
            return (<figure><img className="w-100" src={abstractShapesBottom} alt="shapes bottom" height="64" /></figure>);
        default:
            return (<figure><img className="w-100" src={abstractShapesTop} alt="shapes top" height="64" /></figure>);
    }
}

export default SVG;
